import Ready from './Ready';

const Networking = () => {
  return (
    <>
      <Ready />
    </>
  );
};

export default Networking;
