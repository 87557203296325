export const PART_DATA = {
  web: {
    key: 0,
    value: 'WEB',
    infoDesktop: 'React를 활용하여 협업을 통해서 유지보수성 및 UI/UX를 고려한 실제 웹 서비스를 제공해요.',
    infoTablet: 'React를 활용하여 협업을 통해서 유지보수성 및 UI/UX를 고려한\n실제 웹 서비스를 제공해요.',
    infoMobile: 'React를 활용하여 협업을 통해서 유지보수성 및\nUI/UX를 고려한 실제 웹 서비스를 제공해요.',
    tool: 'Git, GitHub, JavaScript, React, Netlify',
  },
  server: {
    key: 1,
    value: 'SERVER',
    infoDesktop: '실제 서비스에 사용되는 API 서버를 설계하고,\nSpring Boot를 통해 실제 작동하는 서버로 구현/배포해요.',
    infoTablet: '실제 서비스에 사용되는 API 서버를 설계하고, Spring Boot를 통해\n실제 작동하는 서버로 구현/배포해요.',
    infoMobile: '실제 서비스에 사용되는 API 서버를 설계하고,\nSpring Boot를 통해 실제 작동하는 서버로 구현/배포해요.',
    tool: 'Git, GitHub, Java, SpringBoot, Redis, MySQL, AWS',
  },
  // design: {
  //   key: 2,
  //   value: 'DESIGN',
  //   infoDesktop: '나의 아이디어를 기획, 디자인하고 개발 파트와의\n협업을 통해 실제 서비스로 만들어요.',
  //   infoTablet: '나의 아이디어를 기획, 디자인하고 개발 파트와의 협업을 통해\n실제 서비스로 만들어요.',
  //   infoMobile: '나의 아이디어를 기획, 디자인하고 개발 파트와의\n협업을 통해 실제 서비스로 만들어요.',
  //   tool: '아이디어검증, 방법론, 비즈니스모델 등 서비스기획 기본개념, Figma',
  // },
};
